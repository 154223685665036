import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';




import GitHubIcon from '@mui/icons-material/GitHub'; 
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from "@material-ui/core/styles";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { Grid } from '@material-ui/core/';

import csharplogo from './images/csharplogo.png'
import pythonlogo from './images/pythonlogo.png'
import javalogo from './images/javalogo.png'
import weblogo from './images/weblogo.png'

import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from 'react-scroll-section';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

function gitLink(){
  var link = "https://www.patrick-cummins.com";
  window.open(link, "_blank");
}

function App() {   
  return (    
    <div className="App">      
      <Navbar class="" bg="dark" variant="dark" fixed="top">
        <Container>        
          <Navbar.Brand href="#about">Patrick Cummins</Navbar.Brand>          
          <Nav className="me-auto navbar-center" navbarScroll>
            <Nav.Link href="#about">About</Nav.Link>            
            <Nav.Link href="#education">Education</Nav.Link>
            <Nav.Link href="#skills">Skills</Nav.Link>
            <Nav.Link href="#projects">Projects</Nav.Link>
          </Nav>          
        </Container>
      </Navbar>      

    <div class="container-fluid p-0">
    <Fade left duration={1000} delay={500} distance="30px">
      <Section class="resume-section" id="about">
        <div class="resume-section-content">
        
          <h1 class="section-header">
            patrick
            <span style={{color: 'darkorange'}}> cummins</span>
          </h1>                   
          <h2 class="sub-header3">Software Engineer</h2>
          <h2 class="sub-header">
            <a href="mailto:patrickcummins315@gmail.com" class="sub-header">patrickcummins315@gmail.com</a>
          </h2>
          <h2 class="sub-header2">0422 735 315</h2>          
          <div>
            <IconButton onClick={() => window.open('https://github.com/Patrick315')}>
                <GitHubIcon onClick="" sx={ [ {fontSize: 60}, {'&:hover': {color: 'darkorange', fontSize: 70}} ]}/>
            </IconButton>
          </div>                   
        </div>
      </Section> 
      </Fade>
      <hr></hr>

      <Fade right duration={1000} delay={500} distance="50px">
      <Section class="resume-section" id="education">        
      <div class="resume-section-content">          
          <div class="d-flex">
            <div class="flex-grow-1">
              <h3 class="mb-0">queensland university of technology</h3>
              <div class="subheading">bachelor of information technology</div>
              <div class="subheading">computer science major</div>
              <div class="subheading3">2019 - 2022</div>
            </div>
          </div>
        </div>
      </Section>
      </Fade>
      <hr></hr>

      <Fade duration={700} delay={500}>
      <Section class="resume-section" id="skills">        
      <div class="resume-section-content">
          <h1 class="mb-01">Programming Languages</h1>
          <div class="icon-list-div">
            <ul class="icon-list">
              <li><img src = {csharplogo} width="100" height="100"/></li>           
              <li><img src = {pythonlogo} width="100" height="100"/></li>
              <li><img src = {javalogo} width="100" height="100"/></li>  
              <li><img src = {weblogo} width="250" height="100"/></li>  
            </ul>
          </div>

          <h1 class="mb-01">Frameworks, Platforms & Tools</h1>          
          <div class="knowledge-list-div">
            <ul class="knowledge-list">
              <li>React</li>                
              <li>| Linux</li>
              <li>| AWS</li>
              <li>| Git</li>
              <li>| SQL/NoSQL Databases</li>             
            </ul>
          </div>

          <h1 class="mb-01">Proficient Knowledge In</h1>          
          <div class="proficient-list-div">
            <ul class="proficient-list">              
              <li>Algorithms</li>
              <li>Discrete Mathematics</li>
              <li>Parallel Computing</li> 
              <li>Networking</li>               
              <li>Agile Development</li>                           
            </ul>
          </div>
          
          
        </div>
      </Section>
      </Fade>
      <hr></hr>

      <Section class="resume-section" id="projects">        
      <div class="resume-section-content">
          <h1 class="mb-01Project">PROJECTS</h1>
          
          <Grid sx={{ flexGrow: 1 }} container spacing={5}>
           <Grid item> 
          <Card sx={{ maxWidth: 345 }}>                          
              <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              Inventory Management System - C#
              </Typography>
              <Typography variant="body2" color="text.secondary">
                 This is a console based inventory management system
                that utilises a Binary Search Tree for object storage,
                 a Heap Sort algorithm for object retrieval as well as administrative and user credentials. 
              </Typography>
              </CardContent>
            
            <CardActions>
              <Button size="small" color="primary" onClick={() => window.open('https://github.com/Patrick315/Inventory-Management-System')}>
                GitHub Repository w/ Technical Report 
              </Button>
            </CardActions>            
          </Card>
          </Grid>

          <Grid item>
          <Card sx={{ maxWidth: 345 }}>                          
              <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              www.patrick-cummins.com
              </Typography>
              <Typography variant="body2" color="text.secondary">
                 This website has been built using various AWS technologies. Including:
                 <ul class="ul">
                   <li class="projectList">S3 Bucket to store static site data.</li>                   
                   <li class="projectList">CloudFront for low latency delivery of site and security certificate.</li>
                   <li class="projectList">Lambda for automatic updates.</li>
                   <li class="projectList">Route 53 for providing domain.</li>
                   <li class="projectList">CodeBuild and CodePipeline for CI/CD</li>                   
                 </ul>
              </Typography>
              </CardContent>                      
          </Card>
          </Grid>

          </Grid>
          
        </div>
      </Section>
      <hr></hr>


    </div>
      
  

    </div>
  );
}

export default App;
